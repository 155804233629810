


































































































































































































































































































































.ppclass {
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}
.zoomlaFoot {
  height: 32px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 32px;
  padding: 30px 0;
}
.footer2 {
  padding: 20px 0;
  border-bottom: 1px solid rgba(90, 96, 111, 0.48);

  & > * {
    margin-top: 10px;
  }

  dd {
    font-size: 12px;
    padding: 2px 0;
  }
}
.dclass {
  margin-right: 30px;
  margin-left: 10px;
  float: right;
  color: #0139d0;
}

.pclass {
  color: #070f75;
  display: inline-block;
}

.grid-content2 {
  border-radius: 4px;
  min-height: 36px;
  width: 480px;
}

.bg-purple-light {
  background: white;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.ctop {
  top: -60px;
}

.ctop1 {
  top: -100px;
}

.ctop2 {
  top: -140px;
}

.solution-myrow {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  height: 250px;
  width: 100%;
}

.myrow {
  //box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  box-shadow: 0px 4.4px 10px rgba(0, 0, 0, 0.03),
  0px 35px 80px rgba(0, 0, 0, 0.06);
}

.product-solution-mycol {
  width: 100%;
  height: 180px;
}

.float-solution-peculiarity-div {
  position: relative;
  top: 30px;
  left: 10px;
  z-index: 1;
  margin: 0;
}

.float-solution-peculiarity-div2 {
  position: relative;
  top: 40px;
  left: 10px;
  z-index: 1;
  margin: 0;
}

.image-solution-style {
  height: 80px;
  width: 80px;
}

.image-solution-style2 {
  height: 40px;
  width: 80px;
}

.title-solution {
  width: 100%;
  margin: 30px 10px 30px 5px;
}
